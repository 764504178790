<template>
  <div>
    <el-dialog
        title="报价详情"
        width="50%"
        append-to-body
        :visible.sync="dialogState"
        center
        @close="close">
      <el-table
          v-if="offerDetail.orderOffer.offerType = 1"
          border
          show-summary
          style="margin-bottom: 3vh"
          :data="offerDetail.clothesList"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}">
        <el-table-column prop="clothesName" label="婚纱名" align="center"></el-table-column>
        <el-table-column prop="clothesPrice" label="款式报价" align="center"></el-table-column>
      </el-table>
      <el-table
          border
          :data="offerDetail.mathList"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}">
          <el-table-column label="计算过程" align="center" v-slot="scope">
            {{scope.row.stagePrice + " " + scope.row.sign+ " " + scope.row.math + " = " + scope.row.mathResult}}
          </el-table-column>
          <el-table-column prop="mathDescribe" label="描述信息" align="center"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "offer-detail",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    offerDetail: {
      type: Object,
      required: true
    }
  },
  created() {

  },
  data() {
    return {
      dialogState: this.state,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
}
</script>

<style scoped>

</style>