<template>
  <div>
    <el-dialog
        title="添加婚纱"
        width="80%"
        top="3vh"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <fieldset style="width:97%">
        <legend>搜索信息</legend>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select v-model="search.styleType" placeholder="款式类型" clearable>
              <el-option
                  v-for="item in styleTypeArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input v-model="search.styleName" placeholder="款式编号 如: 0001" clearable></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="search.clothesShop" placeholder="店铺" clearable @change="queryClothesPosition">
              <el-option
                  v-for="item in clothesShopArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="search.clothesPosition" placeholder="婚纱位置" clearable>
              <el-option
                  v-for="item in clothesPositionArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="queryClothes(1)" size="medium">搜索</el-button>
            <el-button type="warning" @click="reset" size="medium">重置</el-button>
          </el-col>
          <el-col :span="4" v-if="$per('order_add_schedule:order_schedule_rule')">
            <el-button size="medium" type="danger" effect="dark" style="cursor:pointer;" @click="innerVisible = true">
              {{orderScheduleRule === ""?'此订单暂无档期跨度':'订单档期跨度：'+orderScheduleRule}}
            </el-button>
            <el-popover
                placement="left-end"
                width="300"
                trigger="hover">
              <template #default>
            <span style="font-size: 15px">
              <b>参数描述：</b>订单档期跨度是针对当前订单添加婚纱档期时,婚纱在婚期前后自动保留的最大天数。
              <br>
              该参数设定为某确定数值时，会覆盖默认档期跨度。该参数默认为空，当参数为空时则遵守默认档期跨度。
              <br>
              <b>参数范围：</b>最小值为0天，最大值为30天
            </span>
              </template>
              <i slot="reference" class="el-icon-question" style="font-size: 20px;color: #017ffd;margin-left: 10px"></i>
            </el-popover>
          </el-col>
        </el-row>
        <el-dialog
          width="30%"
          title="订单档期跨度"
          :visible.sync="innerVisible"
          @close="innerClose"
          append-to-body>
          <el-row>
            <el-col :span="16" :offset="4">
              <el-form label-width="100px">
                <el-form-item label="订单档期跨度">
                  <el-select v-model="orderScheduleRule" placeholder="请选择订单档期跨度" style="width: 100%">
                    <el-option
                        v-for="item in scheduleDateArray"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
<!--          <el-row style="margin-top: 15px;margin-bottom: 15px;">-->
<!--            <el-col :span="24">-->
<!--              <el-slider-->
<!--                  :max="30"-->
<!--                  v-model="orderScheduleRule"-->
<!--                  @change="change"-->
<!--                  show-input>-->
<!--              </el-slider>-->
<!--            </el-col>-->
<!--          </el-row>-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerClose" size="medium">取 消</el-button>
          <el-button type="primary" @click="scheduleRuleCommit" size="medium">确 定</el-button>
        </span>
        </el-dialog>
      </fieldset>
      <el-row style="margin-left: 15px;margin-top: 15px">
        <el-col :span="12">
          <div style="height: 70vh;overflow: auto;" class="clothes">
              <el-row :gutter="20" style="width: 99%">
                <el-col :span="8" v-for="item in clothesArray" style="margin-bottom: 25px">
                  <el-row>
                    <el-image :src="baseUrl+item.styleImage" style="width: 90%; height: 260px"
                              :preview-src-list="[baseUrl+item.styleImage]" lazy>
                    </el-image>
                  </el-row>
                  <el-row style="width: 90%">
                    <span style='float: left'>{{item.shopName}}</span>
                    <span style='float: right'>{{item.positionName}}</span>
                  </el-row>
                  <div style="text-align: center;margin-top: 5px;width: 90%">
                    <a href="#" style="color:#409eff" @click="chooseClothes(item)">
                      {{item.styleType}}-{{item.styleName}}-{{item.clothesSize}}-{{item.clothesNo}}选择此件
                    </a>
                  </div>
                </el-col>
              </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="height: 70vh;overflow: auto;margin-bottom: 10px" class="clothes">
            <el-row style="font-size: 16px">
              <el-col :span="6">
                客户名：{{order.name}}
              </el-col>
              <el-col :span="10">
                联系方式：{{order.phone}}
              </el-col>
            </el-row>
            <el-divider content-position="left">订单详情</el-divider>
            <el-row style="margin-top: 15px">
              <el-table
                  :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
                  :data="orderList"
                  style="width: 100%"
                  border>
                <el-table-column prop="orderNo" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="createDate" label="下单日期" align="center"></el-table-column>
<!--                <el-table-column prop="orderCity" label="订单城市" align="center" fixed></el-table-column>-->
<!--                <el-table-column prop="weddingDay" label="精确婚期" align="center" fixed></el-table-column>-->
                <el-table-column prop="orderName" label="项目" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="orderPrice" label="订单总价" align="center"></el-table-column>
                <el-table-column label="操作" align="center" v-slot="scope">
                  <el-button-group>
                    <el-button size="mini" type="primary" @click="addOrderOfferOpen(scope.row)">添加报价</el-button>
                  </el-button-group>
                </el-table-column>
              </el-table>
            </el-row>
            <el-divider content-position="left">该客户的所有报价</el-divider>
            <el-row style="margin-top: 15px">
              <el-table
                  :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
                  border
                  :data="orderOfferArray">
                <el-table-column prop="createDate" align="center" label="报价类型" v-slot="scope">
                  {{scope.row.orderOffer.offerType == 0 ? "套餐报价" : "单件报价"}}
                </el-table-column>
                <el-table-column prop="orderDetail" align="center" label="匹配订单号"/>
                <el-table-column prop="orderOffer.originalPrice" align="center" label="原价"/>
                <el-table-column prop="orderOffer.orderPrice" align="center" label="最终价"/>
                <el-table-column align="center" label="操作" width="200" v-slot="scope">
                  <el-button-group>
                    <el-button size="mini" type="primary" @click="offerDetailOpen(scope.row)">详情</el-button>
                    <el-button size="mini" type="warning" @click="matchOfferOpen(scope.row)">匹配</el-button>
                    <el-button size="mini" type="danger" @click="removeOffer(scope.row)">删除</el-button>
                  </el-button-group>
                </el-table-column>
              </el-table>
            </el-row>
            <el-divider content-position="left">该客户的所有婚期</el-divider>
            <el-row style="margin-top: 15px">
              <el-table
                  :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
                  border
                  max-height="110"
                  :data="weddingDayArray">
                <el-table-column prop="weddingDay" align="center" label="精确婚期"/>
                <el-table-column prop="createDate" align="center" label="创建日期"/>
              </el-table>
            </el-row>
            <el-divider content-position="left">该订单的所有婚纱</el-divider>
            <el-row style="margin-top: 15px">
              <el-table
                  :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
                  :data="scheduleList"
                  border>
                <el-table-column prop="orderNo" label="婚纱名" align="center" :show-overflow-tooltip="true" width="120px">
                  <template slot-scope="scope">
                    {{scope.row.styleType}}-{{scope.row.styleName}}-{{scope.row.clothesSize}}-{{scope.row.clothesNo}}
                  </template>
                </el-table-column>
                <el-table-column prop="weddingDate" label="所属婚期" align="center" show-overflow-tooltip width="120px"></el-table-column>
                <el-table-column prop="scheduleDate" label="档期日期" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="是否可用" align="center" width="80px">
                  <template slot-scope="scope">
                    <span :style="{color: scope.row.scheduleState === '撞挡' ? '#f56c6c' : '#409eff'}">
                      {{scope.row.scheduleState}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="220px">
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-button type="warning" size="mini" @click="showStyleImage(scope.row)">图片</el-button>
                      <el-button type="success" size="mini" @click="updateSchedule(scope.row)">修改档期</el-button>
                      <el-button type="danger" size="mini" @click="removeSchedule(scope.row)">移除</el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-divider content-position="left">添加选中礼服</el-divider>
            <el-form label-width="80px" style="width: 97%" :model="form" :rules="rules" ref="form">
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-form-item label="礼服编号" prop="clothes">
                    <el-input v-model="clothesName" placeholder="礼服编号" readonly></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="使用档期" prop="dateAmong">
                    <el-date-picker
                        style="width: 100%"
                        :disabled="scheduleDateFlag"
                        v-model="form.dateAmong"
                        type="daterange"
                        :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        value-format="yyyy-MM-dd"
                        end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-form-item label="精确婚期">
                    <el-select v-model="selectedWedding" @change="weddingDayChange">
                      <el-option
                          v-for="item in weddingDayArray"
                          :key="item.id"
                          :label="item.weddingDay"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-button type="primary" @click="addSchedule">对礼服加档期</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <update-order-schedule v-if="updateScheduleState" :state="updateScheduleState" @close="updateScheduleClose" :clothesSchedule="clothesSchedule"/>
    <add-offer v-if="addOfferState" :state="addOfferState" :order="addOfferOrder" @close="addOrderOfferClose"></add-offer>
    <offer-detail v-if="offerDetailState" :state="offerDetailState" :offerDetail="offerDetail" @close="offerDetailClose"></offer-detail>
    <match-offer v-if="matchOfferState" :state="matchOfferState" @close="matchOfferClose" :orderOffer="matchOrderOffer" :orderList="orderList"></match-offer>
  </div>
</template>

<script>
import updateOrderSchedule from "@/pages/clothes/update-clothes-schedule"
import addOffer from "@/pages/order/add-offer.vue"
import offerDetail from "@/pages/order/offer-detail.vue"
import matchOffer from "@/pages/order/match-offer.vue"

export default {
  name: "order-add-schedule",
  inject:['order'],
  created() {
    this.pageInit()
    this.$nextTick(() => {
      const el = document.querySelector('.clothes');
      const offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        const scrollTop = el.scrollTop;
        const scrollHeight = el.scrollHeight;
        if ((offsetHeight + scrollTop) - scrollHeight >= -1) {
          this.page++
          this.queryClothes(2)
        }
      };
    });
    for (let i = 0; i < 31; i++) {
      this.scheduleDateArray.push({name: i+"天", value: i})
    }
  },
  components: {
    updateOrderSchedule,
    addOffer,
    offerDetail,
    matchOffer
  },
  props: {
    orderAddScheduleState: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return{
      state: this.orderAddScheduleState,
      tenantCrop: localStorage.getItem("tenantCrop"),
      scheduleRule: "",
      search: {
        styleType: "",
        styleName: "",
        clothesShop: "",
        clothesPosition: "",
      },
      clothesShopArray: [],
      styleTypeArray: [],
      clothesPositionArray: [],
      innerVisible: false,
      scheduleDateArray: [],
      rule:"",
      clothesArray: [],
      page: 1,
      limit: 9,
      baseUrl: "https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/",
      loading: false,
      orderList: [],
      clothesName: "",
      form: {
        clothes: "",
        dateAmong: [],
      },
      rules: {
        clothes: [{required: true, message: '请选择礼服', trigger: 'blur'}],
        dateAmong: [{required: true, message: '请选择使用档期', trigger: 'change'}],
      },
      scheduleList: [],
      styleImageList: [],
      weddingDayArray: [],
      selectedWedding: null,
      day: "",
      scheduleDateFlag: true,
      orderScheduleRule: this.order.scheduleRule,
      updateScheduleState: false,
      clothesSchedule: {},
      addOfferState: false,
      addOfferOrder: {},
      orderOfferArray: [],
      offerDetail: {},
      offerDetailState: false,
      matchOfferState: false,
      matchOrderOffer: "",
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    pageInit() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.clothesShopArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryStyleTypeIds().then(response => {
        this.styleTypeArray = JSON.parse(response.data.data)
      })
      this.queryClothesScheduleList()
      this.defaultSchedule()
      this.queryOrderOfferArray()
    },
    queryClothesPosition(shopId) {
      this.$selectUtils.queryPositionIdsByShop(shopId).then(response => {
        this.clothesPositionArray = JSON.parse(response.data.data)
      })
    },
    // 根据婚期初始化档期
    /**
     * 判断该订单是否有档期跨度？
     *    Y:如若有档期跨度则遵从订单档期跨度
     *    N:没有则遵从默认档期跨度(针对所有的订单)
     * 根据档期跨度来添加婚纱档期，并且只能选择档期跨度之内的婚纱使用日期
     */
    async defaultSchedule() {
       const response1 = await this.$axios({
         method: "get",
         url: "/schedule/queryScheduleRule",
         params: {
           tenantCrop: this.tenantCrop
         }
       });
      // this.scheduleRule = response1.data.data.rule
      this.rule = response1.data.data.rule
      const orderScheduleRule = await this.$axios({
        method: "get",
        url: "/order/queryOrderVoById",
        params: {
          id: this.order.id
        }
      })
      this.orderScheduleRule = orderScheduleRule.data.data.scheduleRule
      if (this.orderScheduleRule !== "") {
        this.rule = this.orderScheduleRule
      }
       const response2 = await this.$axios({
         method: "GET",
         url: "/order/orderList",
         params: {
           cusId: this.order.cusId,
           tenantCrop: this.tenantCrop,
         }
       })
      this.orderList = response2.data.data.list
      const response3 = await this.$axios({
        method: "get",
        url: "/weddingDate/queryWeddingDayByCusId",
        params: {
          cusId: this.order.cusId
        }
      })
      this.weddingDayArray = response3.data.data
      if (this.weddingDayArray.length == 1) {
        this.scheduleDateFlag = false
        this.selectedWedding = this.weddingDayArray[0].id
        this.form.dateAmong = this.$dateUtils.getAroundDateToYMD(this.weddingDayArray[0].weddingDay, this.rule)
        let dataArray = this.$dateUtils.getAroundDate(this.weddingDayArray[0].weddingDay, this.rule)
      }
    },
    reset() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
    },
    innerClose() {
      this.innerVisible = false
      this.queryOrderById()
    },
    scheduleRuleCommit() {
      this.$axios({
        method: "post",
        url: "/order/updateScheduleRuleById",
        data: {
          id: this.order.id,
          scheduleRule: this.orderScheduleRule,
        }
      }).then(res => {
        let flag = res.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '订单档期跨度修改成功': res.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        if (flag) {
          setTimeout(this.innerClose, 500)
          this.queryOrderById()

        }
      })
    },
    queryClothes(type) {
      if (type === 1) {
        this.clothesArray = []
        this.page = 1
      }
      this.$axios({
        method: "GET",
        url: "/clothes/clothesList",
        params: {
          ...this.search,
          tenantCrop: this.tenantCrop,
          page: this.page,
          limit: this.limit,
        }
      }).then(response => {
        this.clothesArray.push(...response.data.data.list)
      })
    },
    chooseClothes(data) {
      this.clothesName = data.styleType+"-"+data.styleName+"-"+data.clothesSize+"-"+data.clothesNo
      this.form.clothes = data.clothesId
    },
    queryClothesScheduleList() {
      this.$axios({
        method: "GET",
        url: "/schedule/clothesAndScheduleByOrderId",
        params: {
          orderId: this.order.id,
        },
      }).then(response => {
        this.scheduleList = response.data.data
      })
    },
    //移除档期
    removeSchedule(data) {
      this.$alert("确认要移除改礼服的档期吗？",
          "档期移除", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(()=>{
        this.$axios({
          method: "delete",
          url: "/schedule/removeClothesSchedule",
          params:{
            clothesId: data.clothesId,
            orderId: this.order.id,
            weddingDayId: data.weddingDayId
          }
        }).then(response=> {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '档期移除成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.queryClothesScheduleList()
        })
      }).catch(() => {})
    },
    addSchedule() {
      this.$refs['form'].validate((valid)=>{
        if (valid) {
          this.$alert('确定要添加档期吗','添加档期',{
            dangerouslyUseHTMLString: true,
            type: "success",
            showCancelButton: true
          }).then(()=>{
            this.$axios({
              method: 'post',
              url: '/schedule/addSchedule',
              params: {
                cusId: this.order.cusId,
                orderId: this.order.id,
                tenantCrop: this.tenantCrop,
                clothesId: this.form.clothes,
                dateAmong: this.form.dateAmong[0]+" - "+this.form.dateAmong[1],
                weddingDayId: this.selectedWedding
              }
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '档期添加成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              this.queryClothesScheduleList()
            })
          })
        }
      })
    },
    // 查看订单图片
    async showStyleImage(data) {
      const response = await this.$axios({
        method:"GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: data.styleId,
        }
      });
      this.styleImageList = response.data.data
      this.$viewerApi({
        images: this.styleImageList,
      })
    },
    // 选择婚期
    weddingDayChange(val) {
      this.scheduleDateFlag = false
      let weddingDay = this.weddingDayArray.find(s => s.id === val).weddingDay
      this.form.dateAmong = this.$dateUtils.getAroundDateToYMD(weddingDay, this.rule)
      let dataArray = this.$dateUtils.getAroundDate(weddingDay, this.rule)
    },
    queryOrderById() {
      this.$axios({
        method: "get",
        url: "/order/queryOrderVoById",
        params: {
          id: this.order.id
        }
      }).then(res => {
        this.orderScheduleRule = res.data.data.scheduleRule
        if (this.orderScheduleRule !== "") {
          this.rule = this.orderScheduleRule
          let weddingDay = this.weddingDayArray.find(s => s.id === this.selectedWedding).weddingDay
          this.form.dateAmong = this.$dateUtils.getAroundDateToYMD(weddingDay, this.rule)
          let dataArray = this.$dateUtils.getAroundDate(weddingDay, this.rule)
        }
      })
    },
    updateSchedule(val) {
      this.updateScheduleState = true
      this.clothesSchedule = val
    },
    updateScheduleClose() {
      this.updateScheduleState = false
      this.queryClothesScheduleList()
    },
    addOrderOfferOpen(val) {
      this.addOfferOrder = val
      this.addOfferState = true
    },
    addOrderOfferClose() {
      this.addOfferState = false
      this.queryOrderOfferArray()
    },
    queryOrderOfferArray() {
      this.$axios({
        method: "GET",
        url: "/orderOffer/queryOrderOfferByCusId",
        params: {
          cusId: this.order.cusId
        }
      }).then(response => {
        this.orderOfferArray = response.data.data
      })
    },
    removeOffer(val) {
      this.$axios({
        method: "post",
        url: "/orderOffer/removeOrderOffer",
        data: {
          id: val.orderOffer.id
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "删除成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryOrderOfferArray()
      })
    },
    offerDetailOpen(val) {
      this.offerDetail = val
      this.offerDetailState = true
    },
    offerDetailClose() {
      this.offerDetailState = false
    },
    matchOfferOpen(val) {
      this.matchOrderOffer = val.orderOffer
      this.matchOfferState = true
    },
    matchOfferClose() {
      this.queryOrderOfferArray()
      this.matchOfferState = false
    }
  },
}
</script>

<style scoped>
.el-card__body {
   padding: 0px;
}
a {text-decoration: none}
.clothes::-webkit-scrollbar {
  display: none;
}
</style>