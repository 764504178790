<template>
  <div>
    <el-dialog
        title="订单编辑"
        width="55%"
        top="10vh"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20px" ref="form">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="客户名">
              <el-input v-model="order.name" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="手机号">
              <el-input v-model="order.phone" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单号" prop="orderNo">
              <el-input v-model="form.orderNo" placeholder="请输入订单号" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="订单项目" prop="orderName">
              <el-select v-model="form.orderName" placeholder="订单项目" style="width: 100%" clearable ref="orderName">
                <el-option
                    v-for="item in orderNameArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单日期" prop="createDate">
              <el-date-picker type="date" placeholder="预约日期"
                              v-model="form.createDate"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
<!--          <el-col :span="7">-->
<!--            <el-form-item label="精确婚期" prop="weddingDay">-->
<!--              <el-date-picker type="date" placeholder="精确婚期"-->
<!--                              v-model="form.weddingDay"-->
<!--                              style="width: 100%;"-->
<!--                              value-format="yyyy-MM-dd">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="礼服师" prop="orderDress">
              <el-select v-model="form.orderDress" placeholder="礼服师" style="width: 100%" clearable>
                <el-option
                    v-for="item in orderDressArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="辅销">
              <el-select v-model="form.orderAuxiliaryDress" placeholder="辅销" style="width: 100%" clearable>
                <el-option
                    v-for="item in orderDressArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="化妆师">
              <el-select v-model="form.orderCosmetics" placeholder="化妆师" style="width: 100%" clearable>
                <el-option
                    v-for="item in cosmeticsArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="订单总价" prop="orderPrice">
              <el-input v-model.number="form.orderPrice" placeholder="订单总价"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="收款金额" prop="spareMoney">
              <el-input v-model.number="form.spareMoney" readonly placeholder="收款金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="余额">
              <el-input v-model="form.orderSpare" readonly placeholder="余额"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="订单备注">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="form.orderRemark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider content-position="left">订单婚期</el-divider>
      <el-row>
        <el-col :offset="1">
          <el-button type="primary" size="medium" @click="addWeddingDayOpen">添加婚期</el-button>
        </el-col>
      </el-row>
      <el-dialog
          v-if="addWeddingDayState"
          title="添加婚期"
          width="20%"
          top="35vh"
          :visible.sync="addWeddingDayState"
          append-to-body
          @close="addWeddingDayClose">
        <el-row>
          <el-col :span="22">
            <el-form :model="weddingDayFrom" label-width="100px" ref="addWeddingDay">
              <el-form-item
                  :rules="[{ required: true, message: '请选择精确婚期', trigger: 'change' }]"
                  prop="newAddWeddingDay"
                  label="精确婚期">
                <el-date-picker type="date" placeholder="精确婚期"
                                v-model="weddingDayFrom.newAddWeddingDay"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addWeddingDayClose" size="medium">取 消</el-button>
          <el-button type="primary" size="medium" @click="addWeddingDay">确 认</el-button>
        </span>
      </el-dialog>
      <el-row style="margin-top: 10px">
        <el-col :span="21" :offset="1">
          <el-table
              :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
              border
              max-height="110"
              :data="weddingDayArray">
            <el-table-column prop="weddingDay" align="center" label="精确婚期"/>
            <el-table-column prop="createDate" align="center" label="创建日期"/>
            <el-table-column align="center" label="押金状态" #default="scope">
              <span :style="{color: scope.row.isDeposit == 1 ? '#00cf22' : ''}">
                {{scope.row.isDeposit == 1 ? "押金已收":"押金未收"}}
              </span>
            </el-table-column>
            <el-table-column align="center" label="操作" #default="scope">
              <el-button-group>
                <el-button type="success" size="mini" @click="editWeddingDayOpen(scope.row)">编辑</el-button>
                <el-button type="danger" size="mini" @click="weddingDayDelete(scope.row)">删除</el-button>
              </el-button-group>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="21" :offset="1">
          <el-table
              :header-cell-style="{background:'#fafafc',color:'#606266',padding: '6px 0'}"
              border
              max-height="110"
              :data="orderList">
            <el-table-column prop="orderName" label="订单项目" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="createDate" label="下单日期" align="center" sortable></el-table-column>
            <el-table-column prop="orderPrice" label="订单总价" align="center" ></el-table-column>
            <el-table-column prop="spareMoney" label="收款金额" align="center" ></el-table-column>
            <el-table-column prop="orderRemark" label="备注" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" size="medium" @click="addUpdate">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 档期编辑窗口  -->
    <el-dialog
        v-if="editWeddingDayState"
        title="编辑婚期"
        width="20%"
        top="35vh"
        :visible.sync="editWeddingDayState"
        append-to-body
        @close="addWeddingDayClose">
      <el-row>
        <el-col :span="22">
          <el-form :model="editWeddingDayFrom" label-width="100px" ref="editWeddingDay">
            <el-form-item
                :rules="[{ required: true, message: '请选择精确婚期', trigger: 'change' }]"
                prop="weddingDay"
                label="精确婚期">
              <el-date-picker type="date" placeholder="精确婚期"
                              v-model="editWeddingDayFrom.weddingDay"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editWeddingDayClose" size="medium">取 消</el-button>
        <el-button type="primary" size="medium" @click="weddingDayUpdate">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "order-update",
  inject: ['order'],
  created() {
    this.pageInit()
  },
  props: {
    orderUpdateState: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  data() {
    return {
      state: this.orderUpdateState,
      form: {
        createDate: "",
        weddingDay: "",
        orderNo: this.order.orderNo,
        orderName: "",
        orderDress: "",
        orderAuxiliaryDress: "",
        orderCosmetics: "",
        orderPrice: "",
        spareMoney: this.order.spareMoney,
        orderSpare: "",
        orderRemark: "",
      },
      rules: {
        orderNo: [{required: true, message: '请填写订单号', trigger: 'blur'}],
        createDate: [{required: true, message: '请选择订单日期', trigger: 'change'}],
        weddingDay: [{required: true, message: '请选择精确婚期', trigger: 'change'}],
        orderName: [{required: true, message: '请选择订单项目', trigger: 'change'}],
        orderDress: [{required: true, message: '请选择礼服师', trigger: 'change'}],
        orderPrice: [{required: true, message: '请输入订单总价', trigger: 'blur'}],
      },
      orderNameArray: [],
      orderDressArray: [],
      cosmeticsArray: [],
      weddingDayArray: [],
      addWeddingDayState: false,
      weddingDayFrom: {
        newAddWeddingDay: "",
      },
      editWeddingDayState: false,
      editWeddingDayFrom: {
        weddingDay: "",
      },
      updateWeddingDayId: "",
      flag: false,
      tenantCrop: localStorage.getItem("tenantCrop"),
      orderList:[],
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryProjectsIds(this.$projectsType.order).then(response => {
        this.orderNameArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryDressIds().then(response => {
        this.orderDressArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryCosmeticsIds().then(response => {
        this.cosmeticsArray = JSON.parse(response.data.data);
      })
      this.queryOrder()
      this.queryWeddingDayByOrderId()
      this.queryOrderList()
    },
    close() {
      this.$emit("close");
    },
    queryOrder() {
      this.$axios({
        method: "GET",
        url: "/order/getById",
        params: {
          id: this.order.id
        }
      }).then(response => {
        Object.assign(this.form, response.data.data);
      })
    },
    addUpdate() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert('确认要更新吗？', "更新订单", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() =>{
            this.$axios({
              method: 'PUT',
              url: "/order/updateOrderById",
              params: this.form
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '订单修改成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              if (flag) {
                this.close();
              }
            })
          })
        }
      })
    },
    // 根据订单号查询婚期
    queryWeddingDayByOrderId() {
      this.weddingDayArray = []
      this.$axios({
        method: "get",
        url: "/weddingDate/queryWeddingDayByCusId",
        params: {
          cusId: this.order.cusId
        }
      }).then(response => {
        this.weddingDayArray.push(...response.data.data)
        console.log(this.weddingDayArray)
      })
    },
    // 添加婚期
    addWeddingDayOpen() {
      this.addWeddingDayState = true
    },
    // 关闭添加婚期框
    addWeddingDayClose() {
      this.addWeddingDayState = false
      this.$refs['addWeddingDay'].resetFields()
    },
    // 添加婚期
    addWeddingDay() {
      this.$refs['addWeddingDay'].validate((valid) => {
        if (valid) {
          this.$axios.post('/weddingDate/addWeddingDay', {
            weddingDay: this.weddingDayFrom.newAddWeddingDay,
            createDate: new Date(),
            orderId: this.order.id,
            cusId: this.order.cusId,
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "添加成功" : response.data.msg,
              type: flag ? 'success' : 'error',
              duration: 1500,
            });
            if (flag) {
              setTimeout(this.addWeddingDayClose, 1500)
              this.queryWeddingDayByOrderId()
            }
          })
        }
      })
    },
    // 婚期删除
    weddingDayDelete(val) {
      this.$confirm('确定删除婚期?',  {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          method: "delete",
          url: "/weddingDate/removeWeddingDayById",
          params: {
            id: val.id
          }
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? "删除成功" : response.data.msg,
            type: flag ? 'success' : 'error',
            duration: flag? 1500 : 2000,
          });
          if (flag) {
            this.queryWeddingDayByOrderId()
          }
        })
      })
    },
    // 婚期编辑
    weddingDayUpdate(val) {
      this.$refs['editWeddingDay'].validate((valid) => {
        if (valid) {
          this.$confirm('如果婚期下有档期则同步修改档期,是否确定修改?', '修改婚期', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$axios({
              method: "post",
              url: "/weddingDate/updateWeddingDayById",
              data: {
                id: this.updateWeddingDayId,
                weddingDay: this.editWeddingDayFrom.weddingDay,
                createDate: new Date(),
              }
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? "修改成功" : response.data.msg,
                type: flag ? 'success' : 'error',
                duration: 1500,
              });
              if (flag) {
                this.queryWeddingDayByOrderId()
                this.editWeddingDayState = false
              }
            })
          })
        }
      })
    },
    // 打开婚期编辑页面
    editWeddingDayOpen(val) {
      this.editWeddingDayFrom.weddingDay = val.weddingDay
      this.updateWeddingDayId = val.id
      this.editWeddingDayState = true
    },
    // 关闭婚期编辑页面
    editWeddingDayClose() {
      this.editWeddingDayState = false
    },
    isChange(val) {
      this.$axios({
        method: "post",
        url: "/weddingDate/updateWeddingDayById",
        data: {
          id: val.id,
          isDeposit: val.isDeposit,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" : response.data.msg,
          type: flag ? 'success' : 'error',
          duration: 1500,
        });
        if (!flag) {
          this.queryWeddingDayByOrderId()
        }
      })
    },
    //根据客资id查询订单列表
    queryOrderList() {
      this.$axios({
        method: "GET",
        url: "/order/orderList",
        params: {
          cusId: this.order.cusId,
          tenantCrop:this.tenantCrop,
          limit: 100,
          page: this.page,
        }
      }).then(response => {
        this.orderList=response.data.data.list;
        console.log(this.orderList)
      })
    },
  }
}
</script>

<style scoped>
/deep/ .el-card__body {
  text-align: center;
}
/deep/ .el-icon-plus{
  margin-left: 5px;
}
/deep/ .el-icon-close{
  margin-left: 5px;
}
/deep/ .el-icon-close:hover {
  color: red;
}
</style>