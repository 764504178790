<template>
  <div>
    <el-dialog
        title="添加预约"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="客户名">
              <el-input readonly v-model="cus.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="手机号">
              <el-input readonly v-model="cus.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="微信号">
              <el-input readonly v-model="cus.weChat"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="预约日期" prop="createDate">
              <el-date-picker type="date" placeholder="预约日期"
                              v-model="form.createDate"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="到店日期" prop="appointDate">
              <el-date-picker type="date" placeholder="到店日期"
                              v-model="form.appointDate"
                              value-format="yyyy-MM-dd"
                              style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="到店时间" prop="appointTime">
              <el-time-select
                  v-model="form.appointTime"
                  style="width: 100%;"
                  :picker-options="{
                    start: '06:00',
                    step: '00:30',
                    end: '21:00'
                  }"
                  placeholder="选择时间">
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="预约人" prop="inviter">
              <el-select v-model="form.inviter" placeholder="预约人">
                <el-option
                    v-for="item in inviterArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="预约项目" prop="appointName">
              <el-select v-model="form.appointName" placeholder="预约项目">
                <el-option
                    v-for="item in appointNameArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="预约店铺" prop="appointShop">
              <el-select v-model="form.appointShop" placeholder="预约店铺" ref="shop">
                <el-option
                    v-for="item in appointShopArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="预设礼服师">
              <el-select v-model="form.appointDress" placeholder="预设礼服师">
                <el-option
                    v-for="item in appointDressArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="预设化妆师">
              <el-select v-model="form.appointCosmetics" placeholder="预设化妆师">
                <el-option
                    v-for="item in appointCosmeticsArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="备注">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="form.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="appAdd('form')">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
name: "app-add",
  created() {
   this.pageInit()
  },
  data() {
    return{
      form: {
        createDate: this.$dateUtils.dateToYMDString(new Date()),
        appointDate: "",
        appointTime: "",
        inviter: "",
        appointName: "",
        appointShop: "",
        appointDress: "",
        appointCosmetics: "",
      },
      rules: {
        createDate: [{required: true, message: "请选择预约日期", trigger: "change"}],
        appointDate: [{required: true, message: "请选择到店日期", trigger: "change"}],
        appointTime: [{required: true, message: "请选择到店时间", trigger: "change"}],
        inviter: [{required: true, message: "请选择预约人", trigger: "change"}],
        appointName: [{required: true, message: "请选择预约项目", trigger: "change"}],
        appointShop: [{required: true, message: "请选择预约店铺", trigger: "change"}],
      },
      inviterArray: [],
      appointNameArray: [],
      appointShopArray: [],
      appointDressArray: [],
      appointCosmeticsArray: [],
      tenantCrop: localStorage.getItem("tenantCrop"),
      state: this.appAddState,
      shopText: "",
      stateFlag: 1,
    }
  },
  props:{
    cus:{
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    appAddState:{
      type: Boolean,
      required: true,
      default: false,
    }
  },
  methods: {
    appAdd(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$alert('预约日期：'+ this.form.appointDate
                              +"<br>客户姓名：" + this.cus.name
                              +"<br>预约店铺：" + this.$refs.shop.selectedLabel
                              +"<br>确认要添加吗？"
                              ,'添加预约',
              {
              dangerouslyUseHTMLString: true,
              type: "success",
              center: true,
              showCancelButton: true
          }).then(() => {
            this.form.cusId = this.cus.id;
            this.form.tenantCrop = this.cus.tenantCrop;
            this.form.type = this.stateFlag === 1 ? "售前预约" : "售后预约"
            this.$axios({
              method: "POST",
              url: "/appoint/saveAppoint",
              params: this.form
            }).then(response => {
                let flag = response.data.code === 200
                this.$message({
                  showClose: true,
                  message: flag ? '预约添加成功': response.data.msg,
                  type: flag ? 'success':'error',
                  duration: 1000,
                });
                if (flag) {
                  this.close();
                }
            })
          }).catch(() => {
          });
        } else {
          return false;
        }
      });
    },
    //查询预约人
    pageInit() {
      this.$selectUtils.queryEmpIds().then(response => {
        this.inviterArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.appointShopArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryDressIds().then(response => {
        this.appointDressArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryCosmeticsIds().then(response => {
        this.appointCosmeticsArray = JSON.parse(response.data.data);
      })
      this.ifStateType()
    },
    close() {
      this.$emit("close");
    },
    ifStateType:function (){
      this.$axios({
        method:"get",
        url:"state/getById",
        params:{
          id:this.cus.stateId,
        }
      }).then(response=>{
        var data = response.data.data;
        if (data.stateType === "售后状态"){
          this.stateFlag = 2
          this.saleAppDefaultDressAndCosmetics()
        }
        this.$selectUtils.queryAppProjectsIds(this.$projectsType.appoint
            ,this.stateFlag).then(response => {
          this.appointNameArray = JSON.parse(response.data.data);
        })
      })
    },
    saleAppDefaultDressAndCosmetics:function (){
      this.$axios({
        method:"GET",
        url:"order/saleAppDefaultDressAndCosmetics",
        params:{
          cusId:this.cus.id
        }
      }).then(response=>{
        let data = response.data.data
        //为了防止 上个订单职位是礼服师或化妆师 而现在已经不是这个职位 会出现数字的情况
        if (this.appointDressArray.find(k=>k.value===data.dress)!==undefined){
          this.form.appointDress = data.dress
        }
        if (this.appointCosmeticsArray.find(k=>k.value===data.cosmetics)!==undefined){
          this.form.appointCosmetics = data.cosmetics
        }

      })
    },
  },
}
</script>

<style scoped>
</style>
