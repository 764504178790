<template>
  <div>
    <el-dialog
        title="订单图片"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <div style="height: 60vh;overflow: auto">
        <el-row>
          <el-col :span="14">
            <el-empty :image-size="200" v-if="images.length === 0" description="暂无订单图片"></el-empty>
            <el-collapse v-model="activeImages" v-else>
              <el-collapse-item v-for="(item, index) in images" :title="item.orderName" :name="index">
                <el-image :src="baseURL+item.imageUrl" :preview-src-list="[baseURL+item.imageUrl]">
                </el-image>
                <br/>
                <i class="el-icon-delete delete-btn" @click="deleteImage(item)"></i>
              </el-collapse-item>
            </el-collapse>
          </el-col>
          <el-col :span="10">
            <el-form label-width="80px" :model="form" style="margin-top: 10px;margin-left: 20px" :rules="rules" ref="form">
              <el-form-item label="订单项目" prop="orderName">
                <el-select v-model="form.orderName" placeholder="订单项目" clearable>
                  <el-option
                      v-for="item in orderNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="handleAvatarChangeIcon">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-button type="primary" style="margin-top: 20px" @click="OrderImageSubmit" :loading="loading">上传图片</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "order-images",
  inject: ['order'],
  created() {
    this.queryImages()
    this.$selectUtils.queryProjectsIds(this.$projectsType.order).then(response => {
      this.orderNameArray = JSON.parse(response.data.data);
    })
  },
  props: {
    orderImageState: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      state: this.orderImageState,
      activeImages: [],
      images: [],
      loading: false,
      tenantCrop: localStorage.getItem("tenantCrop"),
      baseURL: "https://order-image-1304365928.cos.ap-shanghai.myqcloud.com/",
      form: {
        orderName: "",
      },
      orderNameArray: [],
      imageUrl: "",
      file: {},
      fileName: "",
      rules: {
        orderName: [{required: true, message: '请选择订单项目', trigger: 'change'}],
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    queryImages() {
      this.$axios({
        method: 'GET',
        url: "/image/orderImagesByCus",
        params: {
          cusId: this.order.cusId,
          tenantCrop: this.tenantCrop
        }
      }).then(response => {
        this.images = []
        for (let i = 0; i < response.data.data.length; i++) {
          this.images.push(response.data.data[i])
          this.activeImages.push(i)
        }
      })
    },
    handleAvatarChangeIcon(file) {
      this.file = file
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    // 订单图片上传
    OrderImageSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.imageUrl === "") {
            this.$message({
              showClose: true,
              message: "请上传图片",
              type: 'error',
              duration: 1500,
            });
            return;
          }
          this.loading = true;
          this.uploadOrderImage().then(value => {
            if (!value) {
              this.$message({
                showClose: true,
                message: "订单图片上传发生错误,请检查后重新上传",
                type: 'error',
                duration: 1500,
              })
            } else {
              this.$axios({
                method: "POST",
                url: "/image/saveOrderImages",
                params: {
                  orderImage: this.fileName,
                  cusId: this.order.cusId,
                  registrant:JSON.parse(localStorage.getItem("emp")).id,
                  tenantCrop: this.tenantCrop,
                  orderProject: this.form.orderName
                }
              }).then(response => {
                let flag = response.data.code === 200
                this.$message({
                  showClose: true,
                  message: flag ? "图片添加成功" :response.data.msg,
                  type: flag? 'success' :'error',
                  duration: 1000,
                })
                this.file = {}
                this.fileName = ""
                this.imageUrl = ""
                this.queryImages()
                this.loading = false
              })
            }
          })
        }
      })

    },
    uploadOrderImage: function () {
      return new Promise((resolve, reject) => {
        if (this.imageUrl) {
          this.$upload.orderImageUpload(this.file.raw)
              .then(response => {
                let data = response.data
                if (data.code === 200) {
                  this.fileName = data.data
                  resolve(true)
                } else {
                  reject(false)
                }
              })
        } else {
          resolve(true)
        }
      })
    },
    deleteImage(data) {
      this.$alert("确认要删除的这张图片吗？"
        ,'删除图片',
        {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showCancelButton: true
      }).then(()=> {
        this.$axios({
          method: 'delete',
          url: '/image/deleteOrderImage',
          params: {
            orderImage: data.imageUrl,
            cusId: this.order.cusId,
            tenantCrop: this.tenantCrop,
          },
        }).then(response => {
          let flag = response.data.code === 200;
          this.$message({
            showClose: true,
            message: flag ? '图片删除成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          this.queryImages()
        })
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.delete-btn:hover{
  color: red;
  cursor:pointer;
}
</style>