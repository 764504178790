<template>
  <div>
    <el-dialog
        title="追加收款"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <div style="height: 23vh;overflow: auto;width: 100%;">
        <el-form label-width="100px" :model="form" :rules="rules" ref="form">
          <el-row>
            <el-col :span="7">
              <el-form-item label="收款项目" prop="proceedsName">
                <el-select placeholder="请选择收款项目" v-model="form.proceedsName" style="width: 100%;" ref="proceedsName" @change="proceedsNameChange">
                  <el-option
                      v-for="item in proceedsNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="收款金额" prop="spareMoney">
                <el-input v-model.number="form.spareMoney" placeholder="请输入收款金额"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="收款人" prop="payee">
                <el-select placeholder="请选择收款人" v-model="form.payee" style="width: 100%;" ref="service">
                  <el-option
                      v-for="item in payeeArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="收款日期" prop="createDate">
                <el-date-picker type="date" placeholder="收款日期"
                                v-model="form.createDate"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="收款方式" prop="payment">
                <el-cascader
                    placeholder="请选择收款方式"
                    :props="{expandTrigger: 'hover',
                            label: 'name'}"
                    multiple="false"
                    :show-all-levels="false"
                    style="width: 100%;"
                    :options="paymentArray"
                    v-model="form.payment"
                    ref="payment"
                    @change="cascaderClsoe"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="7" v-if="depositFlag">
              <el-form-item label="收押婚期" prop="weddingId">
                <el-select placeholder="请选择收押婚期" v-model="form.weddingId">
                  <el-option
                      v-for="item in weddingDayArray"
                      :key="item.id"
                      :label="item.weddingDay"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider content-position="left">客户{{order.name}}的历史收款记录</el-divider>
        <el-row>
          <el-col :span="7" :offset="1">
            订单总价：{{order.orderPrice}}
          </el-col>
          <el-col :span="7" :offset="1">
            已收金额：{{order.spareMoney}}
          </el-col>
          <el-col :span="7" :offset="1">
            余额：{{order.orderSpare}}
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="addProceeds()">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "add-to-proceeds",
  inject: ['order'],
  created() {
    this.pageInit()
  },
  props: {
    addProceedsState: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      state: this.addProceedsState,
      form: {
        proceedsName: "",
        spareMoney: "",
        payee: "",
        createDate: this.$dateUtils.dateToYMDString(new Date()),
        payment: "",
        weddingId: null,
      },
      rules: {
        proceedsName: [{required: true, message: "请选择收款项目", trigger: "change"}],
        spareMoney: [{required: true, message: "请选择收款项目", trigger: "blur"}],
        payee: [{required: true, message: "请选择收款项目", trigger: "change"}],
        createDate: [{required: true, message: "请选择收款项目", trigger: "change"}],
        payment: [{required: true, message: "请选择收款项目", trigger: "change"}],
        weddingId: [{required: true, message: "请选择婚期", trigger: "change"}],
      },
      proceedsNameArray: [],
      payeeArray: [],
      paymentArray: [],
      weddingDayArray: [],
      isDeposit: false,
      proceedsNameText: "",
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryAddProceedProjects().then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPayeeIds().then(response => {
        this.payeeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryPaymentIds().then(response => {
        this.paymentArray = JSON.parse(response.data.data);
      })
      this.queryWeddingArray()
    },
    close() {
      this.$emit("close");
    },
    cascaderClsoe(val) {
      this.form.payment = val.length===1?val[0]:val.length===2?val[1]: "",
      this.$refs.payment.dropDownVisible = false;
    },
    addProceeds() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert("确认要追加这条 <b>"+ this.form.spareMoney +"</b> 的收款吗？"
              ,'追加收款',
              {
                dangerouslyUseHTMLString: true,
                type: "success",
                showCancelButton: true
          }).then(()=>{
            if (this.proceedsNameText === "押金") {
              this.$axios({
                method: "post",
                url: "/proceeds/addDepositProcess",
                data: {
                  tenantCrop: localStorage.getItem("tenantCrop"),
                  cusId: this.order.cusId,
                  proceedsName: this.form.proceedsName,
                  payment: this.form.payment,
                  payee: this.form.payee,
                  spareMoney: this.spareMoney,
                  weddingId: this.form.weddingId
                }
              }).then(response => {
                let flag = response.data.code === 200
                this.$message({
                  showClose: true,
                  message: flag ? '收押金成功': response.data.msg,
                  type: flag ? 'success':'error',
                  duration: 1000,
                });
                if (flag) {
                  this.close();
                }
              })
            } else {
              this.$axios({
                method: "post",
                url: "/proceeds/saveProceeds",
                params: {
                  cusId: this.order.cusId,
                  tenantCrop: localStorage.getItem("tenantCrop"),
                  orderId: this.order.id,
                  orderPrice: this.order.orderPrice,
                  ...this.form
                }
              }).then((response)=>{
                let flag = response.data.code === 200
                this.$message({
                  showClose: true,
                  message: flag ? '追加收款成功': response.data.msg,
                  type: flag ? 'success':'error',
                  duration: 1000,
                });
                if (flag) {
                  this.close();
                }
              })
            }
          }).catch(()=>{})
        }
      })
    },
    proceedsNameChange(val) {
      let proceedsName = this.proceedsNameArray.find(s => s.value == val).name
      this.proceedsNameText = proceedsName
      if (proceedsName == "押金") {
        this.isDeposit = true
        if (this.weddingDayArray.length == 1) {
          this.form.weddingId = this.weddingDayArray[0].id
        }
      } else {
        this.isDeposit = false
        this.form.weddingId = null
      }
      console.log(this.form.weddingId)
    },
    queryWeddingArray() {
      this.$axios({
        method: "get",
        url: "/weddingDate/queryWeddingDayByCusId",
        params: {
          cusId: this.order.cusId
        }
      }).then(response => {
        this.weddingDayArray.push(...response.data.data)
      })
    },
  },
  computed: {
    depositFlag:function () {
      return  this.weddingDayArray.length > 1 && this.isDeposit
    }
  }
}
</script>

<style scoped>

</style>