<template>
  <div>
    <el-dialog
        title="报价匹配订单"
        width="25%"
        append-to-body
        :visible.sync="dialogState"
        center
        @close="close">
      <el-form label-width="80px" size="medium" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-form-item label="订单编号" prop="orderId">
            <el-input placeholder="请输入要匹配的订单编号" v-model="form.orderId"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" size="medium" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "match-offer.vue",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    orderOffer: {
      type: Object,
      required: true
    },
    orderList: {
      type: Array,
      required: true,
    },
  },
  created() {
  },
  data() {
    return {
      dialogState: this.state,
      form: {
        orderId: '',
      },
      rules: {
        orderId:[{required: true, message: '请输入订单号', trigger: 'blur'}]
      }
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          var matchOrder =  this.orderList.filter(s => s.orderNo === this.form.orderId)
          if (matchOrder.length == 0) {
            this.$message.error("该订单号不存在")
            return
          }
          if (matchOrder[0].orderPrice != this.orderOffer.orderPrice) {
            this.$message.error("最终报价和订单总价不匹配")
            return;
          }
          this.$axios({
            method: "post",
            url: "/orderOffer/matchOrderOffer",
            data: {
              id: this.orderOffer.id,
              orderId: matchOrder[0].id
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message.success("匹配报价成功")
              setTimeout(this.close, 700)
              this.close()
            } else {
              this.$message.error(response.data.msg);
            }
          })
        }
      })
    }

  }
}
</script>

<style scoped>

</style>