<!-- 添加报价 -->
<template>
  <div>
    <el-dialog
        title="添加报价"
        width="50%"
        append-to-body
        :visible.sync="dialogState"
        center
        @close="close">
      <el-form  label-width="70px" size="small">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="客户名">
              <el-input v-model="order.name" readonly/>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="报价方式">
              <el-select v-model="offerType" placeholder="请选择报价方式" @change="offerTypeSelect">
                <el-option
                    v-for="item in offerTypeArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="报价日期" prop="createDate">
              <el-date-picker type="date" placeholder="预约日期"
                              v-model="createDate"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" :offset="1">
            <el-table
                :data="clothesArray"
                style="width: 100%;margin-bottom: 10px"
                size="small"
                border
                :show-header="false"
                v-if="singleOffer">
              <el-table-column v-slot="scope" align="center">
                {{scope.row.styleType+'-'+scope.row.styleName+'-'+scope.row.clothesSize+'-'+scope.row.clothesNo}}
              </el-table-column>
              <el-table-column v-slot="scope">
                <el-input placeholder="价格" size="small" v-model="scope.row.clothesPrice" @input="clothesInput(scope.row.clothesPrice)"></el-input>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="原价">
              <el-input v-model.number="originalPrice" placeholder="原价" :readonly="originalPriceDisable" @input="originalPriceInput"/>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单总价">
              <el-input v-model="order.orderPrice" readonly/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">请给出原价的订单总价之间的计算过程</el-divider>
        <el-row :gutter="20">
          <el-col :span="4" :offset="1">
            <el-input placeholder="阶段价格" v-model="stagePrice" readonly size="small"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="sign" placeholder="计算符号" size="small" @change="signChange">
              <el-option
                  v-for="item in singArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
<!--              <el-input placeholder="计算符号" v-model="sign" readonly size="small"></el-input>-->
          </el-col>
          <el-col :span="4">
            <el-input placeholder="数学算式" v-model="math" size="small" @input="mathInput"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input placeholder="计算结果" v-model="mathResult" readonly size="small"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input placeholder="描述信息" v-model="mathDescribe" size="small"></el-input>
          </el-col>
            <el-button type="primary" size="small" @click="addOfferMath">添加</el-button>
        </el-row>
        <el-table
            :data="offerMathArray"
            style="width: 100%"
            size="small"
            v-if="offerMathArray.length > 0">
          <el-table-column v-slot="scope" align="center">
            {{scope.row.stagePrice+ ' ' + scope.row.sign+ ' ' + scope.row.math + ' = ' + scope.row.mathResult}}
          </el-table-column>
          <el-table-column v-slot="scope" align="center">
            {{scope.row.mathDescribe}}
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" size="medium" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "addOffer",
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    order: {
      type: Object,
      required: true
    }
  },
  created() {
    this.queryClothesArray()
  },
  data() {
    return {
      dialogState: this.state,
      offerType: 0,
      offerTypeArray: [
        {label: "套餐报价", value: 0},
        {label: "单件报价", value: 1}
      ],
      createDate: new Date(),
      originalPrice: "",
      stagePrice: "",
      sign: "",
      math: "",
      mathResult: "",
      mathDescribe: "",
      singArray: [
        {label: '×', value: "×"},
        {label: '－',  value: "－"},
        {label: "+",  value: "+"}],
      offerMathArray: [],
      finalPrice: "",
      originalPriceDisable: false,
      clothesArray: [],
      singleOffer: false,
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    originalPriceInput(val) {
      this.originalPrice = val
      this.stagePrice = Number(this.originalPrice).toFixed(2)
      this.finalPrice = val
      if (this.math != "" && this.sign != null) {
        this.mathInput()
      }
    },
    addOfferMath() {
      let errorMsg = ""
      if (this.stagePrice == "") {
        errorMsg = "请先填写原价"
      } else if (this.sign == "") {
        errorMsg = "请选择计算符号"
      } else if (this.math == "") {
        errorMsg = "请填写数学算式"
      } else if (this.mathDescribe == "") {
        errorMsg = "请填写描述信息"
      }
      if (errorMsg != "") {
        this.$message.error(errorMsg);
        return
      }
      this.finalPrice = this.mathResult
      this.offerMathArray.push({
        stagePrice: this.stagePrice,
        sign: this.sign,
        math: this.math,
        mathResult: this.mathResult,
        mathDescribe: this.mathDescribe,
      })
      this.originalPriceDisable = true
      this.formReset()
    },
    formReset() {
      this.stagePrice = this.mathResult
      this.sign = ""
      this.math = ""
      this.mathResult = ""
      this.mathDescribe = ""
    },
    mathInput() {
      if (this.stagePrice != "" && this.sign != "") {
        this.mathResult = this.calculate(this.stagePrice, this.math, this.sign)
      }
    },
    calculate(val1 ,val2 ,sign) {
      let result
      switch (sign) {
        case "+":
          result = Number(Number(val1) + Number(val2)).toFixed(2)
          break
        case "－":
          result = Number(Number(val1) - Number(val2)).toFixed(2)
          break
        case "×":
          result = Number(Number(val1) * Number(val2)).toFixed(2)
          break
      }
      return result
    },
    offerTypeSelect(val) {
      this.singleOffer = false
      this.originalPriceDisable = false
      if (val === 1) {
        this.singleOffer = true
        this.originalPriceDisable  = true
      }

    },
    queryClothesArray() {
      this.$axios({
        method: "GET",
        url: "/orderOffer/queryOfferClothesByCusId",
        params: {
          cusId: this.order.cusId
        }
      }).then(response => {
        this.clothesArray = response.data.data
      })
    },
    signChange() {
      if (this.math != "" && this.stagePrice != null) {
        this.mathInput()
      }
    },
    submit() {
      if (Number(this.order.orderPrice).toFixed(2) != this.finalPrice) {
        this.$message.error('报价结果和订单总价不一致无法添加');
        return
      }
      this.$axios({
        method: "POST",
        url: "/orderOffer/addOrderOffer",
        data: {
          orderOffer: {
            cusId: this.order.cusId,
            orderId: this.order.id,
            offerType: this.offerType,
            originalPrice: this.originalPrice,
            orderPrice: this.order.orderPrice,
            createDate: new Date(),
            tenantCrop: localStorage.getItem("tenantCrop")
          },
          offerMaths: this.offerMathArray,
          offerClothes: this.clothesArray,
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message({
            message: '报价添加成功!',
            type: 'success'
          });
          setTimeout(()=> {
            this.close()
          }, 500)
        } else {
          this.$message.error(response.data.msg)
        }
      })
    },
    clothesInput() {
      this.originalPrice = 0
      this.clothesArray.forEach(s => {
        if (s.clothesPrice != undefined && s.clothesPrice != "") {
          this.originalPrice = Number(Number(s.clothesPrice) + Number(this.originalPrice)).toFixed(2)
          this.stagePrice = this.originalPrice
          this.finalPrice = this.originalPrice
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-divider--horizontal {
  margin: 10px 0 24px 0;
}
</style>