<template>
  <div>
    <el-dialog
      title="修改档期"
      width="40%"
      top="25vh"
      :visible.sync="updateScheduleState"
      append-to-body
      center
      @close="close">
      <el-form label-width="80px">
        <el-row>
          <el-col :span="11">
            <el-form-item label="婚纱名">
              <el-input readonly v-model="clothesName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="1">
            <el-form-item label="精确婚期">
              <el-input readonly v-model="clothesSchedule.weddingDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="11">
            <el-form-item
                label="使用档期"
                prop="dateAmong">
              <el-date-picker
                  style="width: 100%"
                  @change="changeSchedule"
                  v-model="dateAmongArray"
                  type="daterange"
                  :clearable="false"
                  :picker-options="disablePickOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="1">
            <el-form-item label="是否可用">
              <el-input readonly v-model="scheduleState"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" @click="confirm" size="medium">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "update-clothes-schedule",
  inject:['order'],
  created() {
    console.log(this.clothesSchedule)
    this.defaultSchedule()
  },
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    clothesSchedule: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      updateScheduleState: this.state,
      rule: "",
      tenantCrop: localStorage.getItem("tenantCrop"),
      disablePickOptions:{},
      dateAmongArray: this.clothesSchedule.scheduleDate.split(" - "),
      scheduleState: this.clothesSchedule.scheduleState
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async defaultSchedule() {
      const response1 = await this.$axios({
        method: "get",
        url: "/schedule/queryScheduleRule",
        params: {
          tenantCrop: this.tenantCrop
        }
      });
      this.rule = response1.data.data.rule
      const orderScheduleRule = await this.$axios({
        method: "get",
        url: "/order/queryOrderVoById",
        params: {
          id: this.order.id
        }
      })
      if (orderScheduleRule.data.data.scheduleRule !== "") {
        this.rule = orderScheduleRule.data.data.scheduleRule
      }
      let dataArray = this.$dateUtils.getAroundDate(this.clothesSchedule.weddingDate, this.rule)
      this.disablePickOptions = {
        disabledDate(time) {
          return time.getTime() > dataArray[1].getTime() || time.getTime() < dataArray[0].getTime() - 1 * 24 * 60 * 60 * 1000
        }
      }
    },
    // 修改档期之后
    changeSchedule(val) {
      this.$axios({
        method: "get",
        url: "/schedule/judgeIsConflict",
        params: {
          scheduleIds: this.clothesSchedule.clothesScheduleIds,
          clothesId: this.clothesSchedule.clothesId,
          dateAmong: val[0] +" - "+ val[1],
        }
      }).then(response => {
        if (response.data.data) {
          this.scheduleState = '撞档'
        } else {
          this.scheduleState = '可用'
        }
      })
    },
    confirm() {
      this.$confirm('确认要修改此档期吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          method: "post",
          url: "/schedule/updateSchedule",
          data: {
            clothesId: this.clothesSchedule.clothesId,
            dateAmong: this.dateAmongArray[0] + " - " + this.dateAmongArray[1],
            cusId: this.order.cusId,
            weddingDayId: this.clothesSchedule.weddingDayId,
            orderId: this.order.id,
            tenantCrop: this.tenantCrop
          }
        }).then(response => {
          let flag = response.data.code == 200
          this.$message({
            showClose: true,
            message: flag ? '档期修改成功': response.data.msg,
            type: flag ? 'success':'error',
            duration: 1000,
          });
          if (flag) {
            setTimeout(this.close, 500);
          }
        })
      }).catch(() => {})
    }
  },
  computed: {
    clothesName: function () {
      return this.clothesSchedule.styleType+'-'+this.clothesSchedule.styleName+'-'+this.clothesSchedule.clothesSize+'-'+this.clothesSchedule.clothesNo
    }
  }
}
</script>

<style scoped>

</style>